import { BaseLookup } from "./base.lookup";

export class DriverTypesLookup extends BaseLookup {

    // Values
    static readonly LEASE_PURCHASE = new DriverTypesLookup('1', 'Lease Purchase');
    static readonly COMPANY_DRIVER = new DriverTypesLookup('2', 'Company Driver');
    static readonly OWNER_OPERATOR = new DriverTypesLookup('3', 'Owner Operator');

    static readonly values = [
        DriverTypesLookup.COMPANY_DRIVER,
        DriverTypesLookup.LEASE_PURCHASE,
        DriverTypesLookup.OWNER_OPERATOR
    ];
}