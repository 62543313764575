<!-- content -->
<div class="w-full grid grid-cols-12 gap-3">
  <!--Stas & Filters-->
  <fuse-card class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mb-4 p-4 grid grid-cols-12"
    style="height: fit-content;">
    <div class="text-2xl col-span-12"><b>Stats & Filters</b></div>

    <!-- type -->
    <!-- <mat-form-field appearance="fill" class="col-span-12 mt-1">
      <mat-select [(value)]="selectedViewType" (valueChange)="invalidateStats()">
        <mat-option *ngFor="let type of appViewTypes" [value]="type.id">{{type.name}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- date range -->
    <mat-form-field appearance="fill" class="col-span-12 mt-1">
      <mat-select [(value)]="selectedRange" (valueChange)="getApplications()">
        <mat-option *ngFor="let range of dateRanges" [value]="range.id">{{range.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="col-span-12" *ngIf="selectedRange === dateRangesRaw.CUSTOM.id">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label><small>Custom Date Range*</small></mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="customRange.startDate">
          <input matEndDate placeholder="End date" [(ngModel)]="customRange.endDate" (ngModelChange)="customRangeDatePickerDidChange()">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <!-- states -->
    <mat-form-field appearance="fill" class="col-span-12">
      <mat-select [(value)]="selectedHiringState" (valueChange)="invalidateStats()">
        <mat-option *ngFor="let state of hiringStates" [value]="state.id">{{state.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--apps-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>{{applicationViewTypeFromId(selectedViewType).name}}</b>
    </div>
    <div class="col-span-8">Total</div>
    <div class="col-span-4 text-right pb-1">{{appsTotal()}}</div>
    <div class="col-span-8">Direct</div>
    <div class="col-span-4 text-right pb-1">{{appsDirect()}}</div>
    <div class="col-span-8">Matched</div>
    <div class="col-span-4 text-right pb-1">{{appsMatched()}}</div>
    <div class="col-span-12">
      <hr class="my-2" />
    </div>

    <!--additional-->
    <div class="col-span-8">% Max Sends Used</div>
    <div class="col-span-4 text-right pb-1">{{percentMaxSends()}}</div>
    <div class="col-span-8">CDL Holders</div>
    <div class="col-span-4 text-right pb-1">{{cdlHolders()}}</div>
    <div class="col-span-8">States</div>
    <div class="col-span-4 text-right pb-1">{{states()}}</div>
    <div class="col-span-12">
      <hr class="my-2" />
    </div>

    <!--driver types-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Driver Types</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of driverTypes">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="invalidateStats()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{driverTypeTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>

    <!--experience types-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Experience Types</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of experienceTypes">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="invalidateStats()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{experienceTypeTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>

    <!--freight types-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Freight Types</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of freightTypes">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="invalidateStats()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{freightTypeTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>

    <!--additional details-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Additional Details</b></div>
    <div class="col-span-8">Apps w/ violations</div>
    <div class="col-span-4 text-right pb-1">{{appsWithViolations()}}</div>
    <div class="col-span-8">Apps w/ accidents</div>
    <div class="col-span-4 text-right pb-1">{{appsWithAccidents()}}</div>
    <div class="col-span-8">Apps w/ military exp.</div>
    <div class="col-span-4 text-right pb-1">{{appsWithMilitaryExp()}}</div>
    <div class="col-span-8">Interested in lease programs?</div>
    <div class="col-span-4 text-right pb-1">{{interestInLP()}}</div>
    <div class="col-span-8">Interested in teaming up?</div>
    <div class="col-span-4 text-right pb-1">{{interestInTeams()}}</div>
    <div class="col-span-12">
      <hr class="my-2" />
    </div>
  </fuse-card>

  <!--Application Data-->
  <div class="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
      <!-- charts -->
      <mat-tab label="Charts">
        <div class="grid grid-cols-12 w-full gap-3 bg-card shadow rounded">
          <div class="col-span-12 sm:col-span-6 lg:col-span-7">
            <div *ngIf="chartAppsOverTime != null" class="flex flex-col flex-auto rounded-2xl overflow-hidden p-6"
              style="min-height: 450px;">
              <div class="flex items-start justify-between">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">
                  {{applicationViewTypeFromId(selectedViewType).name}} Over Time</div>
              </div>
              <div class="flex flex-col flex-auto mt-6 h-44">
                <apx-chart class="flex flex-auto items-center justify-center w-full h-full"
                  [chart]="chartAppsOverTime.chart" [colors]="chartAppsOverTime.colors"
                  [labels]="chartAppsOverTime.labels" [plotOptions]="chartAppsOverTime.plotOptions"
                  [series]="chartAppsOverTime.series" [states]="chartAppsOverTime.states"
                  [tooltip]="chartAppsOverTime.tooltip" [xaxis]="chartAppsOverTime.xaxis"
                  [stroke]="chartAppsOverTime.stroke"></apx-chart>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-5">
            <div *ngIf="chartUtmSources != null" class="flex flex-col flex-auto rounded-2xl overflow-hidden p-6"
              style="min-height: 450px;">
              <div class="flex items-start justify-between">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">Total Percentage</div>
                <div class="ml-2">
                  <button class="h-6 min-h-6 px-2 rounded-full bg-hover" mat-button
                    [matMenuTriggerFor]="conversionMenu">
                    <span class="font-medium text-sm text-secondary">{{selectedSliceType.name}}</span>
                  </button>
                  <mat-menu #conversionMenu="matMenu">
                    <button mat-menu-item *ngFor="let type of applicationSliceTypes" (click)="selectSliceType(type)">
                      <span *ngIf="type.id == selectedSliceType.id">
                        <mat-icon>check</mat-icon>
                      </span>
                      <span>{{type.name}}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div class="flex flex-col flex-auto mt-6 h-44">
                <apx-chart class="flex flex-auto items-center justify-center w-full h-full"
                  [chart]="chartUtmSources.chart" [colors]="chartUtmSources.colors" [labels]="chartUtmSources.labels"
                  [plotOptions]="chartUtmSources.plotOptions" [series]="chartUtmSources.series"
                  [states]="chartUtmSources.states" [tooltip]="chartUtmSources.tooltip"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <!--map-->
      <mat-tab label="Map">
        <ng-template matTabContent>
          <mgl-map [style]="'mapbox://styles/mapbox/light-v11'" [style.width]="'100%'" [style.height]="'400px'"
            [style.borderRadius]="'16px'" [zoom]="3" [center]="[-95.7, 37]" (mapCreate)="map = $event"
            (mapLoad)="onMapLoad($event)" [attributionControl]="true" [preserveDrawingBuffer]="true">
          </mgl-map>
        </ng-template>
      </mat-tab>
    </mat-tab-group>





    <!--apps-->
    <div class="flex items-center mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)"
            placeholder="Filter {{applicationViewTypeFromId(selectedViewType).name.toLowerCase()}}...">
        </mat-form-field>

      </div>
      <!-- export to .csv button -->
      <!--loading-->
      <div *ngIf="exportViewState == viewStates.loading" class="w-32">
        <mat-spinner class="m-auto" diameter="30"></mat-spinner>
      </div>
      <button *ngIf="exportViewState == viewStates.content" class="ml-4"
        mat-flat-button [color]="'primary'" (click)="downloadLeadsCsv()">
        <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
        <span class="ml-2 mr-1">.csv</span>
      </button>
    </div>

    <fuse-card class="w-full p-4 mt-5" style="max-height: 780px;">
      <!--loading-->
      <div *ngIf="viewState == viewStates.loading" class="w-full">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <!-- no content -->
      <div *ngIf="viewState == viewStates.content && filteredApplications().length === 0" class="p-4 w-full">
        <app-generic-no-content title="No Applications Found"
          subtitle="No applications were found please refresh or update your search criteria."
          imageUrl="https://img.icons8.com/color/256/000000/clipboard.png" class="ml-auto mr-auto mt-5">
        </app-generic-no-content>
      </div>

      <!--content-->
      <div class="w-full overflow-x-auto mx-6">
        <cdk-virtual-scroll-viewport tvsItemSize class="wrapper mat-elevation-z2">
          <table class="w-full bg-transparent" mat-table matSort [dataSource]="applicationsDataSource"
            [trackBy]="trackByFn" #applicationsTable>

            <!--id-->
            <ng-container matColumnDef="id">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                #
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  #{{application.id}}
                </small>
              </td>
            </ng-container>

            <!-- timestamp -->
            <ng-container matColumnDef="created">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Created
              </th>
              <td mat-cell *matCellDef="let application">
                <span class="pr-6 whitespace-nowrap text-sm">
                  {{moment(application.timestampCreated).format('lll')}}
                </span>
              </td>
            </ng-container>

            <!-- First -->
            <ng-container matColumnDef="firstName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                First
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.firstName}}
                </small>
              </td>
            </ng-container>

            <!-- Last -->
            <ng-container matColumnDef="lastName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Last
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.lastName}}
                </small>
              </td>
            </ng-container>

            <!--email-->
            <ng-container matColumnDef="email">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Email
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 font-medium whitespace-nowrap">
                  {{application.email}}
                </small>
              </td>
            </ng-container>

            <!--phone-->
            <ng-container matColumnDef="phone">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Phone
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.phone}}
                </small>
              </td>
            </ng-container>

            <!-- DOB -->
            <ng-container matColumnDef="dob">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                DOB
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 font-medium whitespace-nowrap">
                  {{application.dobYear}}-{{application.dobMonth}}-{{application.dobDay}}
                </small>
              </td>
            </ng-container>

            <!--city-->
            <ng-container matColumnDef="city">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                City
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.hiringZip.city}}
                </small>
              </td>
            </ng-container>

            <!--zip-->
            <ng-container matColumnDef="zip">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Zip
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.hiringZip.name}}
                </small>
              </td>
            </ng-container>

            <!--state-->
            <ng-container matColumnDef="state">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                State
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.hiringState.abbreviation}}
                </small>
              </td>
            </ng-container>

            <!-- CDL -->
            <ng-container matColumnDef="cdl">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                CDL
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{serializedLookups(application.cdlClasses)}}
                </small>
              </td>
            </ng-container>

            <!--experience-->
            <ng-container matColumnDef="experience">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Experience
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.experienceType.name}}
                </small>
              </td>
            </ng-container>

            <!--accidents-->
            <ng-container matColumnDef="accidents">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Acc.
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.accidents}}
                </small>
              </td>
            </ng-container>

            <!--violations-->
            <ng-container matColumnDef="violations">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Viol.
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.violations}}
                </small>
              </td>
            </ng-container>

            <!--endorsements-->
            <ng-container matColumnDef="endorsements">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Endorsements
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="whitespace-nowrap" *ngFor="let endorsement of application.endorsements; let i = index">
                  <span *ngIf="i != 0">, </span>
                  <span [matTooltip]="endorsement.name">{{endorsement.abbreviation}}</span>
                </small>
              </td>
            </ng-container>

            <!--driver type-->
            <ng-container matColumnDef="driverType">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Driver Type
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.driverType.name}}
                </small>
              </td>
            </ng-container>

            <!--Interest OO or LP-->
            <ng-container matColumnDef="interestOOorLP">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                InterestOOorLP
              </th>
              <td mat-cell *matCellDef="let application">
                <small *ngIf="application.interestInOoLp != null" class="pr-6 whitespace-nowrap">
                  <span *ngIf="application.interestInOoLp">YES</span>
                  <span *ngIf="!application.interestInOoLp">NO</span>
                </small>
              </td>
            </ng-container>

            <!--Power unit or own authority-->
            <ng-container matColumnDef="hasOwnAuthority">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Power Unit or Own Auth
              </th>
              <td mat-cell *matCellDef="let application">
                <small *ngIf="application.hasOwnAuthority != null" class="pr-6 whitespace-nowrap">
                  <span *ngIf="application.hasOwnAuthority">Own Authority</span>
                  <span *ngIf="!application.hasOwnAuthority">Power Unit</span>
                </small>
              </td>
            </ng-container>

            <!--solo or team-->
            <ng-container matColumnDef="teamDriver">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Solo or Team
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  <span *ngIf="application.teamDriver">Team</span>
                  <span *ngIf="!application.teamDriver">Solo</span>
                </small>
              </td>
            </ng-container>

            <!--Interest in team-->
            <ng-container matColumnDef="interestInTeamDriving">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Interest Team Driver
              </th>
              <td mat-cell *matCellDef="let application">
                <small *ngIf="application.interestInTeamDriving != null" class="pr-6 whitespace-nowrap">
                  <span *ngIf="application.interestInTeamDriving">YES</span>
                  <span *ngIf="!application.interestInTeamDriving">NO</span>
                </small>
              </td>
            </ng-container>

            <!--freight-->
            <ng-container matColumnDef="freight">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Freight
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.freightType.name}}
                </small>
              </td>
            </ng-container>

            <!-- interested freight -->
            <ng-container matColumnDef="interestedFreightTypes">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Interested Freight
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{serializedLookups(application.interestedFreightTypes)}}
                </small>
              </td>
            </ng-container>

            <!--military-->
            <ng-container matColumnDef="militaryExperience">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Military Exp
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  <span *ngIf="application.militaryExperience">YES</span>
                  <span *ngIf="!application.militaryExperience">NO</span>
                </small>
              </td>
            </ng-container>

            <!--contact time-->
            <ng-container matColumnDef="applicationContactTimeId">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Best Contact Time
              </th>
              <td mat-cell *matCellDef="let application">
                <small class="pr-6 whitespace-nowrap">
                  {{application.applicationContactTime.name}}
                </small>
              </td>
            </ng-container>

            <!-- Footer -->
            <!-- <ng-container matColumnDef="recentOrdersTableFooter">
                <td
                    class="py-6 px-0 border-0"
                    mat-footer-cell
                    *matFooterCellDef
                    colspan="6">
                    <button mat-stroked-button>See all applications</button>
                </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="applicationsTableColumns(); sticky: true" class="bg-white"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: applicationsTableColumns();"></tr>
            <!-- <tr
                class="h-16 border-0"
                mat-footer-row
                *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
        </cdk-virtual-scroll-viewport>
      </div>
    </fuse-card>
  </div>
</div>