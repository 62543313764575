import { Component, Input, OnInit } from '@angular/core';
import { AgencyDto } from 'app/models/dtos';

@Component({
  selector: 'app-agency-card',
  templateUrl: './agency-card.component.html',
  // styleUrls: ['./agency-card.component.css']
})
export class AgencyCardComponent implements OnInit {
  constructor() { }

  //inputs
  @Input() agency: AgencyDto;

  ngOnInit() {
  }

}
