import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { PieChartDataPair } from 'app/models/app/charts/pieChartDataPair';
import { DateRanges } from 'app/models/app/dateRanges';
import { ApplicationSliceTypes } from 'app/models/app/applicationSliceTypes';
import { AbbreviatedLookupDto, ApplicationDto, DriverTypeDto, HiringStateDto, LookupDto } from 'app/models/dtos';
import { CdlClassesLookup, DriverTypesLookup, ExperienceTypesLookup, FreightTypesLookup, LeadTypesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { ApexAxisChartSeries, ApexOptions, ApexXAxis } from 'ng-apexcharts';
import { ApplicationViewTypes } from 'app/models/app/applicationViewTypes';
import { HiringStatesLookup } from 'app/models/lookups/hiringStates.lookup';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { StackedChartAppSet } from 'app/models/app/charts/stackedChartAppSet';
import { DateSlices } from 'app/models/app/dateSlices';
import { saveAs } from 'file-saver';
import { CsvImportRow, CsvImportRowMappers } from 'app/models/integrations/csv/csvImportRow';
import { AnySourceData, Map } from 'mapbox-gl';
import { TdusaMapElements } from '../../maps/tdusa-map-elements';

@Component({
  selector: 'app-leads-analytics',
  templateUrl: './leads-analytics.component.html',
  styleUrls: ['./leads-analytics.component.css']
})
export class LeadsAnalyticsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  //inputs
  @Input() applicationRoute: string;

  //view children
  @ViewChild('applicationsTable', { read: MatSort, static: false }) applicationsTableMatSort: MatSort;

  //vars
  applications: ApplicationDto[] = []
  _filteredApplications: ApplicationDto[] = null; //cache
  searchText: string = '';
  customRange: any = {
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().toDate()
  }
  applicationsDataSource: TableVirtualScrollDataSource<ApplicationDto> = new TableVirtualScrollDataSource([]);
  applicationsTableColumns(): string[] {
    return [
      'id',
      'created',
      'firstName',
      'lastName',
      'dob',
      'email',
      'phone',
      'zip',
      'city',
      'state',
      'cdl',
      'experience',
      'accidents',
      'violations',
      'endorsements',
      'driverType',
      'interestOOorLP',
      'hasOwnAuthority',
      'teamDriver',
      'interestInTeamDriving',
      'freight',
      'interestedFreightTypes',
      'militaryExperience',
      'applicationContactTimeId',
    ];
  }

  moment = moment;
  selectedViewType = ApplicationViewTypes.APPS.id;
  selectedRange = DateRanges.THIS_WEEK.id;
  selectedHiringState = HiringStatesLookup.ALL.id;
  selectedSliceType: ApplicationSliceTypes = ApplicationSliceTypes.STATE;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  exportViewState = ViewState.content;

  //type lists
  driverTypes = structuredClone(DriverTypesLookup.values);
  experienceTypes = structuredClone(ExperienceTypesLookup.values);
  freightTypes = structuredClone(FreightTypesLookup.values);
  appViewTypes: ApplicationViewTypes[] = structuredClone(ApplicationViewTypes.values);
  dateRanges: DateRanges[] = structuredClone(DateRanges.values);
  hiringStates: HiringStatesLookup[] = structuredClone(HiringStatesLookup.values);

  //raw types
  appViewTypesRaw = ApplicationViewTypes;
  dateRangesRaw = DateRanges;

  //charts
  applicationSliceTypes: ApplicationSliceTypes[] = structuredClone(ApplicationSliceTypes.values);
  chartAppsOverTime: ApexOptions;
  chartUtmSources: ApexOptions;
  _slicePieData: PieChartDataPair[];
  _sliceChartData: ApexAxisChartSeries;

  //map
  map: Map;
  hasLoadedZipsSource: boolean = false;

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.getApplications();
  }

  //api
  getApplications(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_client}/${this.applicationRoute}`, {
        params: this.applicationsQueryString()
      })
      .subscribe((result: ApplicationDto[]) => {
        this.applications = result;
        // for (let index = 0; index < 12; index++) {
        //   this.applications = this.applications.concat(this.applications);
        // }
        this.viewState = ViewState.content;
        this.invalidateStats();
      });
  }

  applicationsQueryString(): any {
    var range = this.dateRanges.find(range => range.id == this.selectedRange);

    if (range.id == '7') {
      //custom
      return {
        startDate: moment(this.customRange.startDate).format('yyyy-MM-DD'),
        endDate: moment(this.customRange.endDate).format('yyyy-MM-DD')
      };
    }
    else {
      return range.params;
    }
  }

  filteredApplications(): ApplicationDto[] {
    //if no cache hit, calculate based on free text and filters in place
    if (this._filteredApplications == null) {
      var uniquefiedApps = this.typeFilteredApps(this.uniqueifyApps(this.applications));

      if (this.searchText.trim() == '') {
        this._filteredApplications = uniquefiedApps;
        console.log(`empty: ${this._filteredApplications}`);
      }
      else {
        this._filteredApplications = uniquefiedApps
          .filter(r =>
            r.firstName.toLowerCase().includes(this.searchText.toLowerCase())
            || r.lastName.toLowerCase().includes(this.searchText.toLowerCase())
            || r.email.toLowerCase().includes(this.searchText.toLowerCase())
          );
        console.log(`filtered: ${this._filteredApplications}`);
      }
    }
    return this._filteredApplications;
  }

  uniqueifyApps(apps: ApplicationDto[]): ApplicationDto[] {
    return apps;
  }

  typeFilteredApps(apps: ApplicationDto[]): ApplicationDto[] {
    var filtApps = apps;

    //type
    filtApps = filtApps.filter(a => !a.blocked);

    //states
    if (this.selectedHiringState != '0') {
      filtApps = filtApps.filter(a => a.hiringState.id == this.selectedHiringState);
    }

    //driver types
    if (this.driverTypes.filter(dt => dt.checked).length > 0) {
      filtApps = filtApps.filter(a => {
        return this.driverTypes.filter(dt => dt.checked)
          .find(dt => dt.id == a.driverType.id) != undefined;
      });
    }
    //experience types
    if (this.experienceTypes.filter(et => et.checked).length > 0) {
      filtApps = filtApps.filter(a => {
        return this.experienceTypes.filter(et => et.checked)
          .find(et => et.id == a.experienceType.id) != undefined;
      });
    }
    //freight types
    if (this.freightTypes.filter(ft => ft.checked).length > 0) {
      filtApps = filtApps.filter(a => {
        return this.freightTypes.filter(ft => ft.checked)
          .find(ft => ft.id == a.freightType.id) != undefined;
      });
    }
    return filtApps;
  }

  seachTextDidChange(text: string) {
    this.invalidateStats();
  }

  updateSorting(applications: ApplicationDto[]) {
    this.applicationsDataSource = new TableVirtualScrollDataSource(applications);
    this.applicationsDataSource.sort = this.applicationsTableMatSort;
    this.applicationsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  serializedLookups(lookups: LookupDto[]): string {
    return lookups.map(l => l.name).join(', ');
  }

  serializedAbbreviatedLookups(lookups: AbbreviatedLookupDto[]): string {
    return lookups.map(l => l.abbreviation).join(', ');
  }

  selectApplicationViewType(applicationViewTypeid: string) {
    this.selectedViewType = applicationViewTypeid;
    this.invalidateStats();
  }

  applicationViewTypeFromId(applicationViewTypeId: string): ApplicationViewTypes {
    return this.appViewTypes.find(t => t.id == applicationViewTypeId);
  }

  //stats/filters
  invalidateStats() {
    this._filteredApplications = null;
    this.updateSorting(this.filteredApplications());
    this.invalidateCharts();
    if (this.map != null) {
      this.updateMapZips();
    }
  }
  appsTotal(): number {
    return this.filteredApplications()
      .map(a => this.viewTypeValue(a))
      .reduce((partialSum, a) => partialSum + a, 0);
  }
  appsDirect(): number {
    return this.filteredApplications()
      .filter(a => a.company != null)
      .map(a => this.viewTypeValue(a))
      .reduce((partialSum, a) => partialSum + a, 0);
  }
  appsMatched(): number {
    return this.filteredApplications()
      .filter(a => a.company == null)
      .map(a => this.viewTypeValue(a))
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  percentMaxSends(): number {
    return 0;
  }
  cdlHolders(): number {
    return this.filteredApplications()
      .filter(a => a.cdlClasses.filter(c => c.id == CdlClassesLookup.CLASS_A.id || c.id == CdlClassesLookup.CLASS_B.id).length > 0)
      .map(a => this.viewTypeValue(a))
      .reduce((partialSum, a) => partialSum + a, 0);
  }
  states(): number {
    return 0;
  }

  driverTypeTotal(type: DriverTypeDto): number {
    return this.filteredApplications()
      .filter(a => a.driverType.id == type.id)
      .map(a => this.viewTypeValue(a))
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  experienceTypeTotal(type: DriverTypeDto): number {
    return this.filteredApplications()
      .filter(a => a.experienceType.id == type.id)
      .map(a => this.viewTypeValue(a))
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  freightTypeTotal(type: DriverTypeDto): number {
    return this.filteredApplications()
      .filter(a => a.freightType.id == type.id)
      .map(a => this.viewTypeValue(a))
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  appsWithViolations(): number {
    return this.filteredApplications().filter(a => a.violations > 0).length;
  }

  appsWithAccidents(): number {
    return this.filteredApplications().filter(a => a.accidents > 0).length;
  }

  appsWithMilitaryExp(): number {
    return this.filteredApplications().filter(a => a.militaryExperience).length;
  }

  interestInLP(): number {
    return this.filteredApplications().filter(a => a.interestInOoLp).length;
  }

  interestInTeams(): number {
    return this.filteredApplications().filter(a => a.interestInTeamDriving).length;
  }

  //chart
  invalidateCharts() {
    //invalidate local caches
    this._slicePieData = null;
    this._sliceChartData = null;

    //rebuild charts
    this.buildCharts();
  }

  buildCharts() {
    this.chartAppsOverTime = {
      series: this.chartSeries(this.selectedSliceType),
      chart: {
        type: 'line',
        height: 400,
        width:400,
        stacked: true,
        toolbar: {
          show: false
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2,    
    },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          },
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        },
      },
      xaxis: this.chartXAxis(),
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };

    this.chartUtmSources = {
      series: this.sliceChartData(this.selectedSliceType).map(t => t.value),
      chart: {
        width: '100%',
        height: 400,
        type: 'donut',
      },
      labels: this.sliceChartData(this.selectedSliceType).map(t => t.label),
      legend: {
        position: 'bottom',
        offsetY: 40,
        show: false
      }
    };
  }

  selectSliceType(type: ApplicationSliceTypes) {
    this.selectedSliceType = type;
    this.invalidateCharts();
  }

  sliceChartData(type: ApplicationSliceTypes): PieChartDataPair[] {
    //check cache
    if (this._slicePieData != null) { return this._slicePieData; }

    var pairs = {};
    this.filteredApplications().forEach(a => {
      var value = a[`${type.propName}`];
      switch (type.propName) {
        case 'hiringState':
          value = (value as HiringStateDto).abbreviation;
          break;
        case 'experienceType':
        case 'freightType':
        case 'driverType':
          value = (value as LookupDto).name;
          break;
        default: break;
      }

      //clean up bool values
      if (typeof value == "boolean") {
        value = value ? 'Yes' : 'No';
      }

      if (pairs[value] != null) {
        pairs[value].value = pairs[value].value + this.viewTypeValue(a);
      }
      else {
        pairs[value] = new PieChartDataPair(value, this.viewTypeValue(a));
      }
    });

    return Object.values(pairs);
  }

  viewTypeValue(application: ApplicationDto): number {
    return 1;
  }

  chartSeries(type: ApplicationSliceTypes): ApexAxisChartSeries {
    //check cache
    if (this._sliceChartData != null) { return this._sliceChartData; }

    var appSets = {};
    this.filteredApplications().forEach(a => {
      var value = a[`${type.propName}`];
      switch (type.propName) {
        case 'hiringState':
          value = (value as HiringStateDto).abbreviation;
          break;
        case 'experienceType':
        case 'freightType':
        case 'driverType':
          value = (value as LookupDto).name;
          break;
        default: break;
      }

      //clean up bool values
      if (typeof value == "boolean") {
        value = value ? 'Yes' : 'No';
      }

      if (appSets[value] != null) {
        appSets[value].apps.push(a);
      }
      else {
        appSets[value] = new StackedChartAppSet(value, [a]);
      }
    });

    //map app sets to date range
    var range = this.dateRanges.find(r => r.id == this.selectedRange);
    var series: ApexAxisChartSeries = Object.values(appSets).map((as: StackedChartAppSet) => {
      const buckets: number[] = new Array(range.span).fill(0);

      as.apps.forEach(app => {
        switch (range.id) {
          case DateRanges.THIS_WEEK.id:
          case DateRanges.LAST_WEEK.id:
            var bucketIndex = moment(app.timestampCreated).weekday() - 1;
            buckets[bucketIndex] += this.viewTypeValue(app);
            break;
          case DateRanges.THIS_MONTH.id:
          case DateRanges.LAST_MONTH.id:
            var bucketIndex = moment(app.timestampCreated).date() - 1;
            buckets[bucketIndex] += this.viewTypeValue(app);
            break;
          case DateRanges.YTD.id:
            var bucketIndex = moment(app.timestampCreated).month();
            buckets[bucketIndex] += this.viewTypeValue(app);
            break;
          default:
            break;
        }
      });

      return {
        name: as.label,
        data: buckets,
      }
    });

    this._sliceChartData = series;
    return series;
  }

  chartXAxis(): ApexXAxis {
    var range = this.dateRanges.find(r => r.id == this.selectedRange);

    var ticks: string[] = [...Array(range.span).keys()].map(offset => {
      switch (range.id) {
        case DateRanges.THIS_WEEK.id:
        case DateRanges.LAST_WEEK.id:
        case DateRanges.THIS_MONTH.id:
        case DateRanges.LAST_MONTH.id:
          return moment(range.start).add(offset, 'days').format('D');
        case DateRanges.YTD.id:
          return moment(range.start).add(offset, 'months').format('MMM');
        default:
          break;
      }

    });

    // return {
    //   type: 'datetime',
    // min: new Date(range.start.setHours(0, 0, 0, 0)).getTime(), // start date
    // max: new Date(moment(range.start).add(range.span, 'days').toDate().setHours(24, 0, 0, 0)).getTime(), // end date
    // tickAmount: 1, // interval you want
    // labels: {
    //     show: true,
    //     formatter: function(val, timestamp) {
    //         return moment(new Date(timestamp)).format("M/d"); // formats to hours:minutes
    //     }        
    // }
    // }

    return {
      categories: ticks,
      tickPlacement: 'on'
    }
  }

  //Export
  downloadLeadsCsv() {
    this.exportViewState = ViewState.loading;

    try {
      var blob = new Blob([this.csvFromApplications(this.filteredApplications())], { type: 'text/csv' })
      saveAs(blob, "report.csv");
    } catch (error) {

    }

    this.exportViewState = ViewState.content;
  }

  csvFromApplications(applications: ApplicationDto[]): string {

    var rows = applications.map(a => CsvImportRowMappers.fromApplication(a));
    var fields = Object.keys(rows[0] ?? new CsvImportRow());

    var replacer = function (key, value) { return value === null ? '' : value }
    var csv = rows.map(row => {
      return fields.map(fieldName => {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    const csvString = csv.join('\r\n');
    // console.log(csv)
    return csvString
  }

  //map
  onMapLoad(event) {
    this.map = event;

    //map render
    this.updateMapZips();
  }

  updateMapZips() {
    //remove old source
    if (this.hasLoadedZipsSource) {
      this.map.removeLayer(TdusaMapElements.zipsLayerTag);
      this.map.removeSource(TdusaMapElements.zipsSourceTag);
    }

    this.map.addSource(TdusaMapElements.zipsSourceTag, this.zipsMapSource(this.filteredApplications().filter(p => p.hiringZip != null)));
    this.map.addLayer(TdusaMapElements.reportZipsLayer());

    this.hasLoadedZipsSource = true;
  }

  zipsMapSource(positions: ApplicationDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: positions.map(z => TdusaMapElements.circleMarkerForHiringZip(z.hiringZip, 15))
      }
    }
  }

  tabClick(tabEvent: any) {
    if (tabEvent.tab.textLabel === 'Charts') {
      this.hasLoadedZipsSource = false;
      this.map = null
    }
  }

  customRangeDatePickerDidChange() {
    if(this.customRange.startDate == null) { return; }
    if(this.customRange.endDate == null) { return; }

    this.getApplications();
  }
}