import { BaseLookup } from "./base.lookup";

export class ApplicationContactTimeLookup extends BaseLookup {

  // Values
  static readonly ANY_TIME = new ApplicationContactTimeLookup('1', 'Any Time');
  static readonly MORNING = new ApplicationContactTimeLookup('2', 'Morning');
  static readonly AFTERNOON = new ApplicationContactTimeLookup('3', 'Afternoon');
  static readonly EVENING = new ApplicationContactTimeLookup('4', 'Evening');

  static readonly values = [
    ApplicationContactTimeLookup.ANY_TIME,
    ApplicationContactTimeLookup.MORNING,
    ApplicationContactTimeLookup.AFTERNOON,
    ApplicationContactTimeLookup.EVENING
  ];
}