import { BaseLookup } from "./base.lookup";

export class FreightTypesLookup extends BaseLookup {

    // Values
    static readonly DRY_VAN = new FreightTypesLookup('1', 'Dry Van');
    static readonly REFRIGERATED = new FreightTypesLookup('2', 'Refrigerated (Reefer)');
    static readonly FLATBED = new FreightTypesLookup('3', 'Flatbed');
    static readonly TANKER = new FreightTypesLookup('4', 'Tanker');
    static readonly DOUBLES_TRIPLES = new FreightTypesLookup('5', 'Doubles / Triples');
    static readonly INTERMODAL = new FreightTypesLookup('6', 'Intermodal');
    static readonly HOUSEHOLD_GOODS = new FreightTypesLookup('7', 'Household Goods');
    static readonly HAZMAT = new FreightTypesLookup('8', 'Hazmat');
    static readonly SPECIALIZED = new FreightTypesLookup('9', 'Specialized');
    static readonly AUTO_HAULER = new FreightTypesLookup('10', 'Auto Hauler');
    static readonly DOUBLE_PUPS = new FreightTypesLookup('11', 'Double Pups');
    static readonly DRY_BULK_AND_PNEUMATIC = new FreightTypesLookup('12', 'Dry Bulk & Pneumatic');
    static readonly OTHER = new FreightTypesLookup('13', 'Other');

    static override readonly values = [
        FreightTypesLookup.DRY_VAN,
        FreightTypesLookup.REFRIGERATED,
        FreightTypesLookup.FLATBED,
        FreightTypesLookup.TANKER,
        FreightTypesLookup.DOUBLES_TRIPLES,
        FreightTypesLookup.INTERMODAL,
        FreightTypesLookup.HOUSEHOLD_GOODS,
        FreightTypesLookup.HAZMAT,
        FreightTypesLookup.SPECIALIZED,
        FreightTypesLookup.AUTO_HAULER,
        FreightTypesLookup.DOUBLE_PUPS,
        FreightTypesLookup.DRY_BULK_AND_PNEUMATIC,
        FreightTypesLookup.OTHER
    ]

}