/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'leads',
        title: 'Leads',
        type: 'basic',
        icon: 'heroicons_outline:chevron-double-right',
        link: '/leads'
    },
    {
        id: 'jobs',
        title: 'Jobs',
        subtitle: 'All things leads/matches/jobs',
        type: 'group',
        icon: 'heroicons_outline:truck',
        children: [
            {
                id: 'agencies',
                title: 'Agencies',
                type: 'basic',
                icon: 'heroicons_outline:office-building',
                link: '/agencies'
            },
            {
                id: 'companies',
                title: 'Companies',
                type: 'basic',
                icon: 'heroicons_outline:briefcase',
                link: '/companies'
            },
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'leads',
        title: 'Leads',
        type: 'basic',
        icon: 'heroicons_outline:chevron-double-right',
        link: '/leads'
    },
    // {
    //     id: 'agencies',
    //     title: 'Agencies',
    //     type: 'basic',
    //     icon: 'heroicons_outline:office-building',
    //     link: '/agencies'
    // },
    {
        id: 'companies',
        title: 'Companies',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/companies'
    },
];
