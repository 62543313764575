import { BaseLookup } from "./base.lookup";

export class LeadSendServicesLookup extends BaseLookup {

    imageUrl: string;

    constructor(id: string, name: string, imageUrl: string) {
        super(id, name)
        this.imageUrl = imageUrl;
    }

    // Values
    static readonly TENSTREET = new LeadSendServicesLookup('1', 'Tenstreet', '');
    static readonly JOBCAST = new LeadSendServicesLookup('2', 'Jobcast', '');
    static readonly AVATAR_FLEET = new LeadSendServicesLookup('3', 'AvatarFleet', '');
    static readonly EMAIL = new LeadSendServicesLookup('4', 'Email', '');
    static readonly EBE = new LeadSendServicesLookup('5', 'EBE', '');
    static readonly TRUCK_RIGHT = new LeadSendServicesLookup('6', 'TruckRight', '');

}