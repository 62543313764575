import { BaseLookup } from "./base.lookup";

export class ProductStatusTypesLookup extends BaseLookup {

  textColor: string;
  matIcon: string;

  constructor(id: string, name: string, textColor: string, matIcon: string) {
    super(id, name);

    this.textColor = textColor;
    this.matIcon = matIcon;
}

  // Values
  static readonly ALL = new ProductStatusTypesLookup('0', 'All', 'text-slate-500', '');
  static readonly PROSPECT = new ProductStatusTypesLookup('1', 'Prospect', 'text-primary', 'person');
  static readonly ACTIVE = new ProductStatusTypesLookup('2', 'Active', 'text-emerald-500', 'check_circle');
  static readonly RENEWAL_REQUIRED = new ProductStatusTypesLookup('3', 'Renewal Required', 'text-orange-500', 'refresh');
  static readonly SUSPENDED = new ProductStatusTypesLookup('4', 'Suspended', 'text-rose-500', 'do_not_disturb');
  static readonly NOT_INTERESTED = new ProductStatusTypesLookup('5', 'Not Interested', 'text-slate-500', 'linear_scale');
  static readonly ARCHIVED = new ProductStatusTypesLookup('6', 'Archived', 'text-slate-900', 'delete');

  static readonly values = [
    ProductStatusTypesLookup.ALL,
    ProductStatusTypesLookup.PROSPECT,
    ProductStatusTypesLookup.ACTIVE,
    ProductStatusTypesLookup.RENEWAL_REQUIRED,
    ProductStatusTypesLookup.SUSPENDED,
    ProductStatusTypesLookup.NOT_INTERESTED,
    ProductStatusTypesLookup.ARCHIVED
  ];

  static readonly performanceFilterValues = [
    ProductStatusTypesLookup.ACTIVE,
    ProductStatusTypesLookup.RENEWAL_REQUIRED,
  ];

  static valueForId(id: string) : ProductStatusTypesLookup {
    return this.values.find(v => v.id == id);
  }
}