import { BaseLookup } from "./base.lookup";

export class LogEntryTypesLookup extends BaseLookup {
  
    // Values
    static readonly ERROR = new LogEntryTypesLookup('1', 'Error');
    static readonly WARNING = new LogEntryTypesLookup('2', 'Warning');
    static readonly INFO = new LogEntryTypesLookup('3', 'Info');
    static readonly USERS = new LogEntryTypesLookup('4', 'Users');
  
    static readonly values = [
      LogEntryTypesLookup.ERROR,
      LogEntryTypesLookup.WARNING,
      LogEntryTypesLookup.INFO,
      LogEntryTypesLookup.USERS,
    ]
  }