import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  // styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public id: string = '0';
  company: CompanyDto = null;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Companies', '/companies')
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //lookups
  permissionGroup = PermissionGroupsLookup.COMPANY;

  async ngOnInit() {
    //update title
    this.setTitle('Company | Client Portal');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    this.getCompany();
  }

  //api
  getCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_client}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.viewState = ViewState.content;
        this.setTitle(this.company.name);
      });
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Client Portal`);
  }
}
