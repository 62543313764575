import { BaseLookup } from "./base.lookup";

export class ServicesLookup extends BaseLookup {
  
    // Values
    // static readonly ALL = new ServicesLookup('1', 'All');
  
    static readonly values = [
        
    ]
  }