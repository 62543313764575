import { BaseLookup } from "./base.lookup";

export class LogNotificationTypesLookup extends BaseLookup {
  
    // Values
    static readonly EMAIL = new LogNotificationTypesLookup('1', 'Email');
  
    static readonly values = [
      LogNotificationTypesLookup.EMAIL
    ]
  }