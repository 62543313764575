export class ApplicationViewTypes {

    id!: string
    name!: string

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    // Values
    static readonly APPS = new ApplicationViewTypes('2', 'Applications');

    static readonly values = [
        ApplicationViewTypes.APPS,
    ]
}