import { BaseLookup } from "./base.lookup";

export class SocialMediaLookup extends BaseLookup {
    constructor(id: string, name: string, imageUrl: string) {
        super(id, name)

        this.imageUrl = imageUrl;
    }

    //props
    imageUrl: string;

    // Values
    static readonly NONE = new SocialMediaLookup(null, 'N/A', '');
    static readonly FACEBOOK = new SocialMediaLookup('facebook', 'Facebook', 'https://img.icons8.com/color/100/null/facebook.png');
    static readonly TWITTER = new SocialMediaLookup('twitter', 'Twitter', 'https://img.icons8.com/color/100/000000/twitter-squared.png');
    static readonly INSTAGRAM = new SocialMediaLookup('instagram', 'Instagram', 'https://img.icons8.com/color/100/000000/instagram.png');
    static readonly YOUTUBE = new SocialMediaLookup('youtube', 'Youtube', 'https://img.icons8.com/color/100/000000/youtube.png');
    static readonly TIKTOK = new SocialMediaLookup('tiktok', 'TikTok', 'https://img.icons8.com/color/100/null/tiktok--v1.png');

    static readonly values = [
        SocialMediaLookup.NONE,
        SocialMediaLookup.FACEBOOK,
        SocialMediaLookup.TWITTER,
        SocialMediaLookup.INSTAGRAM,
        SocialMediaLookup.YOUTUBE,
        SocialMediaLookup.TIKTOK,
    ]
}