<a routerLink="/agencies/{{agency.id}}">
  <fuse-card class="flex items-center w-full p-6 filter-info">
    <img
        class="w-10 h-10 mr-6 rounded object-cover"
        *ngIf="agency.imageUrl != null" 
        [src]="agency.imageUrl"
        alt="Card cover image">
        <mat-icon  *ngIf="agency.imageUrl == null" class="mr-6 text-primary w-10 h-10" style="vertical-align: bottom;"
[svgIcon]="'heroicons_solid:office-building'"></mat-icon>
    <div class="flex flex-col">
        <div class="text-2xl font-semibold leading-tight">{{agency.name}}</div>
        <div class="text-md text-secondary">Agency</div>
    </div>
  </fuse-card>
</a>