import { BaseLookup } from "./base.lookup";

export class MatchedLeadsProductEmailModesLookup extends BaseLookup {

    // Values
    static readonly SEND_TO_EVERYONE = new MatchedLeadsProductEmailModesLookup('1', 'Send to Everyone');
    static readonly ROUND_ROBIN = new MatchedLeadsProductEmailModesLookup('2', 'Round-robin Style');

    static readonly values = [
        MatchedLeadsProductEmailModesLookup.SEND_TO_EVERYONE,
        MatchedLeadsProductEmailModesLookup.ROUND_ROBIN
    ]
}
