import { BaseLookup } from "./base.lookup";

export class CdlClassesLookup extends BaseLookup {

  // Values
  static readonly CLASS_A = new CdlClassesLookup('1', 'Class A');
  static readonly CLASS_B = new CdlClassesLookup('2', 'Class B');
  static readonly CLASS_C = new CdlClassesLookup('3', 'Standard Driver\'s License');

  static readonly values = [
    CdlClassesLookup.CLASS_A,
    CdlClassesLookup.CLASS_B,
    CdlClassesLookup.CLASS_C
  ]
}