import { ApplicationDto } from "app/models/dtos";
import { DriverTypesLookup } from "app/models/lookups";

export class CsvImportRow {
    createdAt?: Date;
    referralType?: string
    matchedCount?: string
    maxSends?: string
    chosenCount?: string
    directOrMatched?: string
    filteredCompanies?: string
    firstName?: string
    lastName?: string
    cdlLicense?: string
    cdlNumber?: string
    cdlStateIssued?: string
    cdlDateExpired?: string
    accidents?: string
    violations?: string
    endorsements?: string
    experience?: string
    email?: string
    dob?: string
    zip?: string
    city?: string
    state?: string
    phone?: string
    interestCdlTraining?: string
    companyOrOwner?: string
    interestOOorLP?: string
    powerUnitOrOwnAuthority?: string
    truckYear?: string
    truckMake?: string
    dotNumber?: string
    mcNumber?: string
    soloOrTeam?: string
    interestTeamDriver?: string
    mostCommonFreight?: string
    militaryExperience?: string
    bestContactTime?: string
    matchedCompanies?: string
    chosenCompanies?: string
    utm_source?: string
    utm_medium?: string
    utm_campaign?: string
}

export class CsvImportRowMappers {
    static fromApplication(application: ApplicationDto): CsvImportRow {
        var row = new CsvImportRow();
        row.createdAt = application.timestampCreated;
        row.referralType = 'application'//components[1]; //TODO: application or matched
        // row.matchedCount = components[2];
        // row.maxSends = components[3]; //sitewide only
        // row.chosenCount = components[4]; //sitewide only
        row.directOrMatched = application.company == null ? 'matched' : 'direct';
        row.filteredCompanies = '';
        row.firstName = application.firstName;
        row.lastName = application.lastName;
        row.cdlLicense = application.cdlClasses.map(c => c.name).join(',');
        row.cdlNumber = '';
        row.cdlStateIssued = '';
        row.cdlDateExpired = '';
        row.accidents = `${application.accidents}`;
        row.violations = `${application.violations}`;
        row.endorsements = application.endorsements.map(e => e.abbreviation).join(' '); //TODO: convert
        row.experience = application.experienceType.name
        row.dob = `${application.dobMonth}/${application.dobDay}/${application.dobYear}`;
        row.zip = application.hiringZip.name;
        row.city = application.hiringZip.city;
        row.state = application.hiringState.name;
        row.phone = application.phone;
        row.interestCdlTraining = '';
        row.companyOrOwner = (application.driverType.id == DriverTypesLookup.COMPANY_DRIVER.id) ? 'company' : 'owner';
        row.interestOOorLP = (application.interestInOoLp) ? 'yes' : 'no';
        row.powerUnitOrOwnAuthority = (application.hasOwnAuthority == null) ? '' : ((application.hasOwnAuthority) ? 'yes' : 'no');
        row.truckYear = '';
        row.truckMake = '';
        row.dotNumber = '';
        row.mcNumber = '';
        row.soloOrTeam = (application.teamDriver) ? 'team' : 'solo';
        row.interestTeamDriver = (application.interestInTeamDriving) ? 'yes' : 'no';
        row.mostCommonFreight = application.freightType.name;
        row.militaryExperience = (application.militaryExperience) ? 'yes' : 'no';
        row.bestContactTime = application.applicationContactTime.name;
        row.matchedCompanies = ''; //TODO: serialize
        row.chosenCompanies = ''; //TODO: serialize
        row.utm_source = application.utmSource;
        row.utm_medium = application.utmMedium;
        row.utm_campaign = application.utmCampaign;

        return row;
    }
}