import { BaseLookup } from "./base.lookup";

export class SoloTeamLookup extends BaseLookup {

  // Values
  static readonly SOLO = new SoloTeamLookup('1', 'Solo Driver');
  static readonly TEAM = new SoloTeamLookup('2', 'Team Driver');

  static readonly values = [
    SoloTeamLookup.SOLO,
    SoloTeamLookup.TEAM
  ]
}