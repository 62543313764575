<div class="grid grid-cols-12">
  <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
    <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
      <!--loading-->
      <div *ngIf="infoViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <div *ngIf="infoViewState == viewStates.content">
        <div class="text-small text-center text-secondary mb-4">
          <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
          </mat-icon>
          <b>Company Info</b>
        </div>

        <div class="form-group">
          <input type="file" style="display: none;" id="resource-image" accept="image/*"
            #image>
        </div>
        <img *ngIf="company?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
          src="https://img.icons8.com/color/256/000000/add-image.png" />
        <img *ngIf="company?.imageUrl != null"
          class="object-cover w-32 h-32 ml-auto mr-auto border rounded cursor-pointer" src="{{company.imageUrl}}"
          alt="Card cover image">
        <div>
          <div class="flex-auto">
            <!--name-->
            <mat-form-field class="w-full">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="company.name" [autocomplete]="'off'" placeholder="Company Name" [disabled]="true">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </mat-form-field>
            <!--phone-->
            <mat-form-field class="w-full">
              <mat-label>Phone</mat-label>
              <input matInput [(ngModel)]="company.phone" [autocomplete]="'off'" placeholder="Company Phone" [disabled]="true">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-4 text-small text-center text-secondary">Updated
            {{moment(company.timestampUpdated).fromNow()}}</div>
        </div>

      </div>

    </fuse-card>
  </div>
  <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">

    <fuse-card class="pt-5 pb-5 pl-8 pr-8 w-full grid grid-cols-12 gap-3">
      <div class="text-2xl col-span-12 mb-2"><b>Staff Assignments</b></div>
      <div class="col-span-12 md:col-span-6">
        <div class="text-md text-accent"><b>Sales Rep</b></div>
        <app-administrator-card *ngIf="company.salesRep != null" [administrator]="company.salesRep" >
        </app-administrator-card>
      </div>
      <div class="col-span-12 md:col-span-6">
        <div class="text-md text-accent"><b>Ops Rep</b></div>
        <app-administrator-card *ngIf="company.opsRep != null" [administrator]="company.opsRep" >
        </app-administrator-card>
      </div>
    </fuse-card>

    <fuse-card class="pt-5 pb-5 pl-8 pr-8 w-full grid grid-cols-12 gap-3 mt-5">
      <div class="text-2xl col-span-12 mb-2"><b>Associations</b></div>
      <div class="col-span-12">
        <div class="text-md text-accent mb-2"><b>Agency</b></div>
        <div *ngIf="company.agency == null"
          class="flex flex-col items-center justify-center w-full rounded-lg border-2 border-gray-300 border-dashed text-accent bg-default"
          style="min-height: 90px;">
          <div>No company association</div>
        </div>
        <app-agency-card *ngIf="company.agency != null" [agency]="company.agency"></app-agency-card>
      </div>
      <!-- <div class="col-span-12 md:col-span-6">
        <div class="text-md text-accent mb-2"><b>Collective</b></div>
        <div *ngIf="company.collective == null"
          class="flex flex-col items-center justify-center w-full rounded-lg border-2 border-gray-300 border-dashed text-accent bg-default"
          style="min-height: 90px;">
          <div>No collective association</div>
        </div>
        <app-collective-card *ngIf="company.collective != null" [collective]="company.collective"></app-collective-card>
      </div> -->
    </fuse-card>
  </div>
</div>