import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdministratorDto, CompanyDto, } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  // styleUrls: ['./company-info.component.css']
})
export class CompanyInfoComponent implements OnInit {
  constructor(private http: HttpClient, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() company: CompanyDto;

  //vars
  administrators: AdministratorDto[] = []
  moment = moment;
  selectedSalesRepId: string;
  selectedOpsRepId: string;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  infoViewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    this.buildUI();
  }

  buildUI() {
    this.selectedSalesRepId = this.company.salesRep?.id;
    this.selectedOpsRepId = this.company.opsRep?.id;
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Client Portal`);
  }

  //api
  getCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_client}/v1/companies/${this.company.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;

        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
        this.setTitle(this.company.name);
      });
  }
}
