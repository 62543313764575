import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-no-content',
  templateUrl: './generic-no-content.component.html',
  // styleUrls: ['./generic-no-content.component.css']
})
export class GenericNoContentComponent implements OnInit {
  constructor() { }

  //vars
  @Input() title: string;
  @Input() subtitle: string;
  @Input() imageUrl: string;

  ngOnInit() {
  }

}
