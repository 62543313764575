<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs" [terminal]="company?.name ?? ''" [imageUrl]="company?.imageUrl"
    fallbackIcon="heroicons_solid:briefcase"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-2 mt-6">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
      <div class="col-span-12">
        <!--tabs-->
        <mat-tab-group animationDuration="0ms">
          <!--Leads-->
          <mat-tab label="Leads">
            <ng-template matTabContent>
              <app-leads-analytics applicationRoute="v1/companies/{{id}}/applications"></app-leads-analytics>
            </ng-template>
          </mat-tab>

          <!--Info-->
          <mat-tab label="Info">
            <ng-template matTabContent>
              <app-company-info [company]="company"></app-company-info>
            </ng-template>
          </mat-tab>

          <!--Contacts-->
          <mat-tab label="Contacts">
            <ng-template matTabContent>
              <app-company-contacts [company]="company">
              </app-company-contacts>
            </ng-template>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>

  </div>

</div>