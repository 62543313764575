import * as moment from "moment"
import { DateSlices } from "./dateSlices"

export class DateRanges {

    id!: string;
    name!: string;
    params: any;
    slice: DateSlices;
    start: Date;
    span: number;

    constructor(id: string, name: string, params: any, slice: DateSlices, start: Date, span: number) {
        this.id = id;
        this.name = name;
        this.params = params;
        this.slice = slice;
        this.start = start
        this.span = span;
    }

    // Values
    static readonly THIS_WEEK = new DateRanges('1', 'This week', {
        y: moment().year(),
        w: moment().isoWeek()
    }, DateSlices.day, moment().startOf('isoWeek').toDate(), 7);
    static readonly LAST_WEEK = new DateRanges('2', 'Last week', {
        y: moment().subtract(7, 'days').year(),
        w: moment().subtract(7, 'days').isoWeek()
    }, DateSlices.day, moment().startOf('isoWeek').subtract(7, 'days').toDate(), 7);
    static readonly THIS_MONTH = new DateRanges('3', 'This month', {
        y: moment().year(),
        m: moment().month()+1
    }, DateSlices.day, moment().startOf('month').toDate(), moment().daysInMonth());
    static readonly LAST_MONTH = new DateRanges('4', 'Last month', {
        y: moment().subtract(1, 'month').year(),
        m: moment().subtract(1, 'month').month()+1
    }, DateSlices.day, moment().subtract(1,'month').startOf('month').toDate(), moment().subtract(1,'month').daysInMonth());
    static readonly YTD = new DateRanges('5', 'Year to date', {
        y: moment().year()
    }, DateSlices.month, moment().startOf('year').toDate(), 12);
    static readonly ALL_TIME = new DateRanges('6', 'All time', {

    }, DateSlices.month, moment().toDate(), 12);
    static readonly CUSTOM = new DateRanges('7', 'Custom', {

    }, DateSlices.day, moment().toDate(), 12);

    static readonly values = [
        DateRanges.THIS_WEEK,
        DateRanges.LAST_WEEK,
        DateRanges.THIS_MONTH,
        DateRanges.LAST_MONTH,
        DateRanges.YTD,
        DateRanges.ALL_TIME,
        DateRanges.CUSTOM,
    ]
}