import { BaseLookup } from "./base.lookup";
import { PermissionTypesLookup } from "./permissionTypes.lookup";

export class PermissionGroupsLookup extends BaseLookup {

    types: PermissionTypesLookup[];

    constructor(id: string, name: string, types: PermissionTypesLookup[]) {
        super(id, name);

        this.types = types;
    }

    // Values
    static readonly GLOBAL = new PermissionGroupsLookup('1', 'Global', [
        PermissionTypesLookup.SUPER_ADMIN,
        PermissionTypesLookup.ADMIN,
        PermissionTypesLookup.VIEW_CAMPAIGNS,
        PermissionTypesLookup.MANAGE_CAMPAIGNS,
        PermissionTypesLookup.ADMIN_CAMPAIGNS
    ]);
    static readonly COMPANY = new PermissionGroupsLookup('2', 'Company', [
        PermissionTypesLookup.VIEW_COMPANY,
        PermissionTypesLookup.MANAGE_COMPANY,
        PermissionTypesLookup.ADMIN_COMPANY,
    ]);
    static readonly AGENCY = new PermissionGroupsLookup('3', 'Agency', [
        PermissionTypesLookup.VIEW_AGENCY,
        PermissionTypesLookup.MANAGE_AGENCY,
        PermissionTypesLookup.ADMIN_AGENCY,
    ]);
    static readonly CAMPAIGN = new PermissionGroupsLookup('4', 'Campaign', [
        PermissionTypesLookup.VIEW_CAMPAIGN,
        PermissionTypesLookup.MANAGE_CAMPAIGN,
        PermissionTypesLookup.ADMIN_CAMPAIGN,
    ]);

    static values = [
        PermissionGroupsLookup.GLOBAL,
        PermissionGroupsLookup.COMPANY,
        PermissionGroupsLookup.AGENCY,
        PermissionGroupsLookup.CAMPAIGN,
    ];

    static groupForType(permissionTypeId: string) : PermissionGroupsLookup {
        return PermissionGroupsLookup.values.find(g => g.types.find(t => t.id === permissionTypeId) != null);
    }
}