import { BaseLookup } from "./base.lookup";

export class MatchedLeadsProductEmailFormatsLookup extends BaseLookup {

    // Values
    static readonly HTML = new MatchedLeadsProductEmailFormatsLookup('1', 'HTML');
    static readonly PLAIN_TEXT = new MatchedLeadsProductEmailFormatsLookup('2', 'Plain text');
    static readonly JSON = new MatchedLeadsProductEmailFormatsLookup('3', 'JSON');

    static readonly values = [
        MatchedLeadsProductEmailFormatsLookup.HTML,
        MatchedLeadsProductEmailFormatsLookup.PLAIN_TEXT,
        MatchedLeadsProductEmailFormatsLookup.JSON,
    ]
}