import { BaseLookup } from "./base.lookup";

export class ViolationsLookup extends BaseLookup {

  // Values
  static readonly ZERO = new ViolationsLookup('0', '0');
  static readonly ONE = new ViolationsLookup('1', '1');
  static readonly TWO = new ViolationsLookup('2', '2');
  static readonly THREE = new ViolationsLookup('3', '3');
  static readonly FOUR = new ViolationsLookup('4', '4');
  static readonly FIVE_PLUS = new ViolationsLookup('5', '5+');

  static readonly values = [
    ViolationsLookup.ZERO,
    ViolationsLookup.ONE,
    ViolationsLookup.TWO,
    ViolationsLookup.THREE,
    ViolationsLookup.FOUR,
    ViolationsLookup.FIVE_PLUS
  ]
}