import { BaseLookup } from "./base.lookup";

export class EndorsementTypesLookup extends BaseLookup {

    // Values
    static readonly HAZARDOUS_MATERIALS = new EndorsementTypesLookup('1', 'H - Hazardous Materials');
    static readonly TANKER_VEHICLES = new EndorsementTypesLookup('2', 'N - Tanker Vehicles');
    static readonly DOUBLES_OR_TRIPLES = new EndorsementTypesLookup('3', 'T - Doubles or Triples');
    static readonly PASSENGERS = new EndorsementTypesLookup('4', 'P - Passengers');
    static readonly SCHOOL_BUS = new EndorsementTypesLookup('5', 'S - School Bus');
    static readonly COMBO_FOR_HAZMAT_AND_TANK = new EndorsementTypesLookup('6', 'X - Combo for Hazmat and Tank Vehicles');
    static readonly TWIC_CARD = new EndorsementTypesLookup('7', 'TWIC Card');

    static readonly values = [
        EndorsementTypesLookup.HAZARDOUS_MATERIALS,
        EndorsementTypesLookup.TANKER_VEHICLES,
        EndorsementTypesLookup.DOUBLES_OR_TRIPLES,
        EndorsementTypesLookup.PASSENGERS,
        EndorsementTypesLookup.SCHOOL_BUS,
        EndorsementTypesLookup.COMBO_FOR_HAZMAT_AND_TANK,
        EndorsementTypesLookup.TWIC_CARD
    ];
}