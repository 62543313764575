import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewState } from 'app/models/app';
import { CompanyDto, ContactDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-company-contacts',
  templateUrl: './company-contacts.component.html',
  // styleUrls: ['./company-contacts.component.css']
})
export class CompanyContactsComponent implements OnInit {
  constructor(private http: HttpClient) { }

  //inputs
  @Input() company: CompanyDto;

  //vars
  //vars
  contacts: ContactDto[] = [];
  allContacts: ContactDto[] = [];
  searchText: string = '';
  contactsTableColumns: string[] = ['name'];
  moment = moment;
  addSearchText: string = '';
  contactToAdd: ContactDto = null;

  selectedRepContactId: string;
  selectedBillingContactId: string;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addViewState = ViewState.initial;

  ngOnInit() {
    this.getContacts();

    this.selectedRepContactId = this.company.repContact?.id;
    this.selectedBillingContactId = this.company.billingContact?.id;
  }

  //api
  getContacts(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_client}/v1/companies/${this.company.id}/contacts`)
      .subscribe((result: ContactDto[]) => {
        this.contacts = result;
        this.viewState = ViewState.content;
      });
  }

  //search
  seachTextDidChange(text: string) {

  }

  filteredContacts(): ContactDto[] {
    return this.contacts.filter(r =>
      (r.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.phone ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.position ?? '').toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
}
