import { Component, Input, OnInit } from '@angular/core';
import { GenericBreadcrumb } from './genericBreadcrumb';

@Component({
  selector: 'app-generic-breadcrumbs',
  templateUrl: './generic-breadcrumbs.component.html',
  styleUrls: ['./generic-breadcrumbs.component.css']
})
export class GenericBreadcrumbsComponent implements OnInit {
  //inputs
  @Input() terminal: string;
  @Input() imageUrl: string;
  @Input() breadcrumbs: GenericBreadcrumb[] = [];
  @Input() profile: boolean = false;
  @Input() fallbackIcon: string;

  constructor() { }

  ngOnInit() {
  }

}

