import { BaseLookup } from "./base.lookup";

export class AccidentsLookup extends BaseLookup {

  // Values
  static readonly ZERO = new AccidentsLookup('0', '0');
  static readonly ONE = new AccidentsLookup('1', '1');
  static readonly TWO = new AccidentsLookup('2', '2');
  static readonly THREE = new AccidentsLookup('3', '3');
  static readonly FOUR = new AccidentsLookup('4', '4');
  static readonly FIVE_PLUS = new AccidentsLookup('5', '5+');

  static readonly values = [
    AccidentsLookup.ZERO,
    AccidentsLookup.ONE,
    AccidentsLookup.TWO,
    AccidentsLookup.THREE,
    AccidentsLookup.FOUR,
    AccidentsLookup.FIVE_PLUS
  ]
}