import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  // styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //view children
  @ViewChild('companiesTable', { read: MatSort, static: false }) companiesTableMatSort: MatSort;

  //vars
  companies: CompanyDto[] = []
  filteredCompanies: CompanyDto[] = []
  searchText: string = '';
  companiesDataSource: MatTableDataSource<CompanyDto> = new MatTableDataSource();
  companiesTableColumns: string[] = ['name', 'agency.name'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addCompanyViewState = ViewState.content;

  ngOnInit() {
    this.titleService.setTitle('Companies | Client Portal');

  }

  ngAfterViewInit() {
    this.getCompanies();
  }

  //api
  getCompanies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_client}/v1/companies`)
      .subscribe((result: CompanyDto[]) => {
        this.companies = result;
        this.filteredCompanies = this.companies;
        this.viewState = ViewState.content;
        this.companiesDataSource = new MatTableDataSource(this.filteredCompanies);
        this.updateSorting(this.filteredCompanies);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredCompanies = this.companies.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
      || (r.agency?.name ?? '').toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredCompanies);
  }

  updateSorting(companies: CompanyDto[]) {
    this.companiesDataSource = new MatTableDataSource(companies);
    this.companiesDataSource.sort = this.companiesTableMatSort;
    this.companiesDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
