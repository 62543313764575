import { BaseLookup } from "./base.lookup";

export class ExperienceTypesLookup extends BaseLookup {

    // Values
    static readonly NO_EXPERIENCE_REQUIRED = new ExperienceTypesLookup('0', 'No Experience Required');
    static readonly NEED_CDL_TRAINING = new ExperienceTypesLookup('1', 'Need CDL Training');
    static readonly IN_CDL_SCHOOL_NOW = new ExperienceTypesLookup('2', 'In CDL School Now');
    static readonly CDL_GRAD_NO_EXPERIENCE = new ExperienceTypesLookup('3', 'CDL Grad - No Experience Yet');
    static readonly ONE_TO_FIVE_MONTHS = new ExperienceTypesLookup('4', '1-5 Months');
    static readonly SIX_TO_ELEVEN_MONTHS = new ExperienceTypesLookup('5', '6-11 Months');
    static readonly TWELVE_TO_TWENTY_THREE_MONTHS = new ExperienceTypesLookup('6', '12-23 Months');
    static readonly TWO_YEARS = new ExperienceTypesLookup('7', '2 Years');
    static readonly THREE_YEARS = new ExperienceTypesLookup('8', '3 Years');
    static readonly FOUR_YEARS = new ExperienceTypesLookup('9', '4 Years');
    static readonly FIVE_YEARS = new ExperienceTypesLookup('10', '5+ Years');

    static override readonly values = [
        ExperienceTypesLookup.NO_EXPERIENCE_REQUIRED,
        ExperienceTypesLookup.NEED_CDL_TRAINING,
        ExperienceTypesLookup.IN_CDL_SCHOOL_NOW,
        ExperienceTypesLookup.CDL_GRAD_NO_EXPERIENCE,
        ExperienceTypesLookup.ONE_TO_FIVE_MONTHS,
        ExperienceTypesLookup.SIX_TO_ELEVEN_MONTHS,
        ExperienceTypesLookup.TWELVE_TO_TWENTY_THREE_MONTHS,
        ExperienceTypesLookup.TWO_YEARS,
        ExperienceTypesLookup.THREE_YEARS,
        ExperienceTypesLookup.FOUR_YEARS,
        ExperienceTypesLookup.FIVE_YEARS
    ]
}