import { BaseLookup } from "./base.lookup";

export class ProductSuspensionReasonsLookup extends BaseLookup {

    // Values
    static readonly NOT_SET = new ProductSuspensionReasonsLookup('0', 'Not Set');
    static readonly PAUSED = new ProductSuspensionReasonsLookup('1', 'Paused');
    static readonly PERFORMANCE = new ProductSuspensionReasonsLookup('2', 'Performance');
    static readonly CREDIT_HOLD = new ProductSuspensionReasonsLookup('3', 'Credit Hold');
    static readonly WIN_BACK = new ProductSuspensionReasonsLookup('4', 'Win Back');
    static readonly TERMINATED = new ProductSuspensionReasonsLookup('5', 'Terminated');

    static readonly values = [
        ProductSuspensionReasonsLookup.NOT_SET,
        ProductSuspensionReasonsLookup.PAUSED,
        ProductSuspensionReasonsLookup.PERFORMANCE,
        ProductSuspensionReasonsLookup.CREDIT_HOLD,
        ProductSuspensionReasonsLookup.WIN_BACK,
        ProductSuspensionReasonsLookup.TERMINATED
    ];
}