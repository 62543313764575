import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactDto } from 'app/models/dtos';

@Component({
  selector: 'app-contact-card-portrait',
  templateUrl: './contact-card-portrait.component.html',
  // styleUrls: ['./contact-card-portrait.component.css']
})
export class ContactCardPortraitComponent implements OnInit {
  constructor() { }

  @Input() contact: ContactDto;

  inputOptions: ContactCardPortraitOption[]

  ngOnInit() {
    
  }
}


export class ContactCardPortraitOption {

  id: string;
  name: string;
  warn: boolean;

  constructor(id: string, name: string, warn: boolean) {
    this.id = id;
    this.name = name;
    this.warn = warn;
  }
}