<div class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
  <div class="text-2xl font-semibold leading-tight">Special Contacts</div>
  <div class="grid grid-cols-12 gap-4 mt-4">
    <div class="col-span-12 md:col-span-6">
      <div *ngIf="company.repContact == null">
        <div
          class="flex flex-col items-center justify-center w-full rounded-lg border-2 border-gray-300 border-dashed bg-default"
          style="min-height: 285px;">
          <div class="text-secondary text-small text-center mt-2">
            <b>Rep Contact</b>
            <span *ngIf="company.repContact == null"> (unassigned)</span>
          </div>
        </div>

      </div>
      <div *ngIf="company.repContact != null">
        <app-contact-card-portrait [contact]="company.repContact" [options]="[]" >
        </app-contact-card-portrait>
        <div class="mt-2 font-bold text-center">Rep Contact</div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6">
      <div *ngIf="company.billingContact == null">
        <div
          class="flex flex-col items-center justify-center w-full rounded-lg border-2 border-gray-300 border-dashed bg-default"
          style="min-height: 285px;">
          <div class="text-secondary text-small text-center mt-2">
            <b>Billing Contact</b>
            <span *ngIf="company.billingContact == null"> (unassigned)</span>
          </div>
        </div>
      </div>
      <div *ngIf="company.billingContact != null">
        <app-contact-card-portrait [contact]="company.billingContact" [options]="[]" >
        </app-contact-card-portrait>
        <div class="mt-2 font-bold text-center">Billing Contact</div>
      </div>
    </div>

    <div class="col-span-12 mt-5">
      <div class="text-2xl font-semibold w-full">All Contacts</div>
      <div *ngIf="addViewState == viewStates.initial" class="flex items-center mt-4 sm:mt-0 md:mt-4">
        <!-- Search -->
        <div class="flex-auto">
          <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" [placeholder]="'Filter contacts...'">
          </mat-form-field>
        </div>
      </div>

      <!--loading-->
      <div *ngIf="viewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <!-- no content -->
      <div *ngIf="viewState == viewStates.content && filteredContacts().length === 0" class="mt-8">
        <app-generic-no-content title="No Contacts Found"
          subtitle="No contacts were found please refresh or update your search criteria."
          imageUrl="https://img.icons8.com/color/256/000000/business-contact.png" class="ml-auto mr-auto mt-5">
        </app-generic-no-content>
      </div>

      <div class="col-span-12 grid grid-cols-12 gap-3 flex-auto pt-5"
        *ngIf="viewState == viewStates.content && filteredContacts().length > 0">
        <!--contact list-->
        <app-contact-card-portrait class="col-span-12 md:col-span-4 lg:col-span-3 xl:col-span-2"
          *ngFor="let contact of filteredContacts()" [contact]="contact" [options]="[]"></app-contact-card-portrait>

      </div>
    </div>
  </div>

  <!--contacts list-->
</div>