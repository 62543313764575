import { BaseLookup } from "./base.lookup";

export class LogEntryStatesLookup extends BaseLookup {
  
    // Values
    static readonly ALL = new LogEntryStatesLookup('1', 'All');
    static readonly UNRESOLVED = new LogEntryStatesLookup('2', 'Unresolved');
    static readonly RESOLVED = new LogEntryStatesLookup('3', 'Resolved');
  
    static readonly values = [
        LogEntryStatesLookup.ALL,
        LogEntryStatesLookup.UNRESOLVED,
        LogEntryStatesLookup.RESOLVED,
    ]
  }