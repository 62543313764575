import { Component, Input, OnInit } from '@angular/core';
import { AdministratorDto } from 'app/models/dtos';

@Component({
  selector: 'app-administrator-card',
  templateUrl: './administrator-card.component.html',
  // styleUrls: ['./administrator-card.component.css']
})
export class AdministratorCardComponent implements OnInit {
  constructor() { }

  @Input() administrator: AdministratorDto;

  ngOnInit() {

  }
}
