<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Agencies" fallbackIcon="heroicons_solid:office-building"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter agencies'">
        </mat-form-field>
      </div>
    </div>
     

    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredAgencies.length === 0" class="mt-8">
      <app-generic-no-content 
      title="No Agencies Found"
      subtitle="No agencies were found please refresh or update your search criteria."
      imageUrl="https://img.icons8.com/color/256/000000/company.png"
      class="ml-auto mr-auto mt-5"></app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="viewState != viewStates.content || filteredAgencies.length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table
              class="w-full bg-transparent"
              mat-table
              matSort
              [dataSource]="agenciesDataSource"
              [trackBy]="trackByFn"
              #agenciesTable>
  
              <!-- Name -->
              <ng-container matColumnDef="name">
                  <th
                      mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef>
                      Name
                  </th>
                  <td
                      mat-cell
                      *matCellDef="let agency">
                      <a routerLink="/agencies/{{agency.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                        <img class="inline mr-3 rounded" style="width: 24px; vertical-align: middle;" *ngIf="agency.imageUrl != null" [src]="agency.imageUrl" />
                        <mat-icon  *ngIf="agency.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
                    [svgIcon]="'heroicons_solid:office-building'"></mat-icon>
                          <b class="text-slate-600">{{agency.name}}</b>
                      </a>
                  </td>
              </ng-container>

              <tr
                  mat-header-row
                  *matHeaderRowDef="agenciesTableColumns"></tr>
              <tr
                  class="order-row h-16"
                  mat-row
                  *matRowDef="let row; columns: agenciesTableColumns;"></tr>
              <!-- <tr
                  class="h-16 border-0"
                  mat-footer-row
                  *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
      </div>
      </fuse-card>
    </div>
  </div>

</div>