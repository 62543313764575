<!-- Header -->
<div
  class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-6 sm:px-10 border-b bg-card dark:bg-transparent">
  <div class="flex-1 min-w-0">
    <!-- Breadcrumbs -->
    <div class="flex flex-wrap items-center font-medium">
      <div>
        <a class="whitespace-nowrap text-primary-500" routerLink="/">
          <mat-icon>home</mat-icon>
        </a>
      </div>
      <div class="flex items-center ml-1 whitespace-nowrap" *ngFor="let breadcrumb of breadcrumbs">
        <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
        <a class="ml-1 text-primary-500" routerLink="{{breadcrumb.route}}">
          {{breadcrumb.name}}
        </a>
      </div>
      <div class="flex items-center ml-1 whitespace-nowrap">
        <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
        <a class="ml-1 text-primary-500">
          {{terminal}}
        </a>
      </div>
    </div>
    <!-- Title -->
    <div class="mt-2">
      <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
        <img *ngIf="imageUrl != null && !profile" src="{{imageUrl}}" class="w-9 h-9 inline mr-2 rounded object-cover" style="line-height: 40px; vertical-align: bottom;" />
        <img *ngIf="imageUrl != null && profile" src="{{imageUrl}}" class="w-9 h-9 inline mr-2 rounded-full object-cover" style="line-height: 40px; vertical-align: bottom;" />
        <mat-icon *ngIf="imageUrl == null && fallbackIcon != null" class="w-9 h-9 mr-2 text-primary" style="vertical-align: top;"
                    [svgIcon]="fallbackIcon"></mat-icon>
        {{terminal}}
      </h2>
    </div>
  </div>
</div>