export class ApplicationSliceTypes {
    id!: string;
    name!: string;
    propName!: string;

    constructor(id: string, name: string, propName: string) {
        this.id = id;
        this.name = name;
        this.propName = propName;
    }

    // Values
    static readonly STATE = new ApplicationSliceTypes('4', 'State', 'hiringState');
    static readonly DRIVER_TYPE = new ApplicationSliceTypes('5', 'Driver Type', 'driverType');
    static readonly FREIGHT_TYPE = new ApplicationSliceTypes('6', 'Freight Type', 'freightType');
    static readonly EXPERIENCE_TYPE = new ApplicationSliceTypes('7', 'Experience Type', 'experienceType');
    static readonly OWN_AUTHORITY = new ApplicationSliceTypes('8', 'Has Own Authority', 'hasOwnAuthority');
    static readonly TEAM_DRIVER = new ApplicationSliceTypes('9', 'Team Driver', 'teamDriver');

    static readonly values = [
        ApplicationSliceTypes.STATE,
        ApplicationSliceTypes.DRIVER_TYPE,
        ApplicationSliceTypes.FREIGHT_TYPE,
        ApplicationSliceTypes.EXPERIENCE_TYPE,
        ApplicationSliceTypes.OWN_AUTHORITY,
        ApplicationSliceTypes.TEAM_DRIVER
    ]
}