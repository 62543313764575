import { ApplicationDto } from "app/models/dtos";

export class StackedChartAppSet {

    label: string;
    apps: ApplicationDto[] = [];
    
    constructor(label: string, apps: ApplicationDto[]) {
        this.label = label;
        this.apps = apps;
    }
}