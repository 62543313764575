import { BaseLookup } from "./base.lookup";

export class ProspectReasonsLookup extends BaseLookup {
  
    // Values
    static readonly NOT_SET = new ProspectReasonsLookup('0', 'Select a Prospect Reason');
    static readonly COLD_CALL = new ProspectReasonsLookup('1', 'Cold Call');
    static readonly PROPOSAL_REQUESTED = new ProspectReasonsLookup('2', 'Proposal Requested');
    static readonly PROPOSAL_SENT = new ProspectReasonsLookup('3', 'Proposal Sent');
    static readonly OEM = new ProspectReasonsLookup('4', 'OEM');
    static readonly TRADE_SHOW = new ProspectReasonsLookup('5', 'Trade Show');
    static readonly CONTACT_FORM = new ProspectReasonsLookup('6', 'Contact Form');
    static readonly MOBILE_APP = new ProspectReasonsLookup('7', 'Mobile App');
    static readonly FACEBOOK = new ProspectReasonsLookup('8', 'Facebook');
    static readonly MISC = new ProspectReasonsLookup('9', 'Misc');

    static values = [
      ProspectReasonsLookup.NOT_SET,
      ProspectReasonsLookup.COLD_CALL,
      ProspectReasonsLookup.PROPOSAL_REQUESTED,
      ProspectReasonsLookup.PROPOSAL_SENT,
      ProspectReasonsLookup.OEM,
      ProspectReasonsLookup.TRADE_SHOW,
      ProspectReasonsLookup.CONTACT_FORM,
      ProspectReasonsLookup.MOBILE_APP,
      ProspectReasonsLookup.FACEBOOK,
      ProspectReasonsLookup.MISC,
    ];
  }